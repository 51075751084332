// 内容管理
export default [
    // 主播力荐
    {
        path: 'AnchorRecommendation',
        component: () =>
            import('@/views/admin/contentConfig/anchorRecommendation.vue')
    },
    // 热门课程推荐管理
    {
        path: 'Hotmanage',
        component: () =>
            import('@/views/admin/contentConfig/Hotmanage.vue')
    },
    // 轮播图管理
    {
        path: 'rotationManage',
        component: () =>
            import('@/views/admin/contentConfig/rotationManage.vue')
    },
    // 新建轮播图
    {
        path: 'rotationDetail',
        component: () =>
            import('@/views/admin/contentConfig/rotationDetail.vue')
    },
    // 资讯分类
    {
        path: 'InfoClassify',
        component: () =>
            import('@/views/admin/contentConfig/infoClassify.vue')
    },
    // 资讯列表
    {
        path: 'infoList',
        component: () =>
            import('@/views/admin/contentConfig/infoList.vue')
    },
    // 栏目管理 - 新版资讯管理
    {
        path: 'columnManage',
        component: () =>
            import('@/views/admin/contentConfig/informationManage/columnManage.vue')
    },
    // 资讯列表 - 新版资讯管理
    {
        path: 'informationManage',
        component: () =>
            import('@/views/admin/contentConfig/informationManage/index.vue')
    },
    // 资讯列表 - 新版新建资讯
    {
        path: 'informationAdd',
        component: () =>
            import('@/views/admin/contentConfig/informationManage/informationAdd.vue')
    },
    // 栏目配置
    {
        path: 'columnConfig',
        component: () =>
            import('@/views/admin/contentConfig/productRecommend/columnConfig.vue')
    },
    // 栏目新建
    {
        path: 'columnAdd',
        component: () =>
            import('@/views/admin/contentConfig/productRecommend/columnAdd.vue')
    },
    // 栏目详情
    {
        path: 'columnDetail',
        component: () =>
            import('@/views/admin/contentConfig/productRecommend/columnDetail.vue')
    },
    // 新建资讯
    {
        path: 'infoDetail',
        component: () =>
            import('@/views/admin/contentConfig/infoDetail.vue')
    },
    // 问答分类
    {
        path: 'QAClassify',
        component: () =>
            import('@/views/admin/contentConfig/QAClassify.vue')
    },
    // 问答列表
    {
        path: 'QAList',
        component: () =>
            import('@/views/admin/contentConfig/QAList.vue')
    },
    // 新建问答
    {
        path: 'QADetail',
        name: 'QADetail',
        component: () =>
            import('@/views/admin/contentConfig/QADetail.vue')
    },
    // 问题分类
    {
        path: 'questionClassify',
        component: () =>
            import('@/views/admin/contentConfig/questionClassify.vue')
    },
    // 问题列表
    {
        path: 'questionList',
        component: () =>
            import('@/views/admin/contentConfig/questionList.vue')
    },
    // 新建问题
    {
        path: 'questionDetail',
        component: () =>
            import('@/views/admin/contentConfig/questionDetail.vue')
    },
    // 直播管理
    {
        path: 'liveManage',
        component: () =>
            import('@/views/admin/contentConfig/liveManage.vue')
    },
    // 新建直播
    {
        path: 'liveDetail',
        component: () =>
            import('@/views/admin/contentConfig/liveDetail.vue')
    },
    // 结束直播
    {
        path: 'liveEnd',
        component: () =>
            import('@/views/admin/contentConfig/liveEnd.vue')
    },
    // 直播预约
    {
        path: 'liveAppointment',
        component: () =>
            import('@/views/admin/contentConfig/liveAppointment.vue')
    },
    // 萱讲头条
    {
        path: 'Preach',
        component: () =>
            import('@/views/admin/contentConfig/preach.vue')
    },
    // 新建音频
    {
        path: 'preachDetail',
        component: () =>
            import('@/views/admin/contentConfig/preachDetail.vue')
    },
    // 新知共享 分类管理
    {
        path: 'newShairSort',
        component: () =>
            import('@/views/admin/contentConfig/newShairSort.vue')
    },
    // 自闭症之声
    {
        path: 'autismSpeaks',
        component: () =>
            import('@/views/admin/contentConfig/autismSpeaks.vue')
    },
    // 学习资料列表
    {
        path: 'MaterialListManager',
        component: () =>
            import('@/views/admin/contentConfig/MaterialListManager.vue')
    },
    // 新建自闭症之声
    {
        path: 'autismDetail',
        component: () =>
            import('@/views/admin/contentConfig/autismDetail.vue')
    },
    // 打卡设置
    {
        path: 'ClockIn',
        component: () =>
            import('@/views/admin/contentConfig/clockIn.vue')
    },
    // 广告区设置
    {
        path: 'Advertise',
        component: () =>
            import('@/views/admin/contentConfig/Advertise/index.vue')
    },
    // 新建广告
    {
        path: 'AdvertiseDetail',
        component: () =>
            import('@/views/admin/contentConfig/Advertise/detail.vue')
    }
]