export default [
  { // 商品分类
    path: 'classification',
    component: () => import('@/views/admin/Commodity/classification.vue')
  },
  { // 商品列表
    path: 'ProductList',
    component: () => import('@/views/admin/Commodity/ProductList.vue')
  },
  { // 新建商品
    path: 'NewProduct',
    component: () => import('@/views/admin/Commodity/NewProduct.vue')
  },
  { // 新建组合商品
    path: 'NewGroup',
    component: () => import('@/views/admin/Commodity/NewGroup.vue')
  },
  { // 线下商品维护
    path: 'LineDownProduct',
    component: () => import('@/views/admin/Commodity/lineDownProduct.vue')
  },
  { // 商品销售关系管理
    path: 'RelationManager',
    component: () => import('@/views/admin/Commodity/RelationManager.vue')
  },
]